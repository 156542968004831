<script lang="ts" setup>
import {useSkins} from '@core/composable/useSkins'
import {useThemeConfig} from '@core/composable/useThemeConfig'
import DefaultLayoutWithVerticalNav from '@/layouts/components/DefaultLayoutWithVerticalNav.vue'
import {AppContentLayoutNav} from "@layouts/enums.ts";
import axios from 'axios';
import {onMounted} from "vue";
import {useLogto} from "@logto/vue";
import {singInCallbackPath} from "@/logto";
import {useUserStore} from "@/stores/userStore.ts";
import {useCompanyStore} from "@/stores/companyStore.ts";

const {width: windowWidth} = useWindowSize()
const {appContentLayoutNav, switchToVerticalNavOnLtOverlayNavBreakpoint} = useThemeConfig()

onMounted(() => {
  appContentLayoutNav.value = AppContentLayoutNav.Vertical;
})

// ℹ️ This will switch to vertical nav when define breakpoint is reached when in horizontal nav layout
// Remove below composable usage if you are not using horizontal nav layout in your app
switchToVerticalNavOnLtOverlayNavBreakpoint(windowWidth)

const {layoutAttrs, injectSkinClasses} = useSkins()

injectSkinClasses()

const userStore = useUserStore();
const companyStore = useCompanyStore();

const {getAccessToken, isAuthenticated, signIn, fetchUserInfo, getAccessTokenClaims} = useLogto();

axios.interceptors.request.use(async (config) => {
  if (isAuthenticated.value) {
    const accessToken = await getAccessToken(import.meta.env.VITE_LOGTO_RESOURCE);
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }
  return config;
});

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    return signIn(singInCallbackPath);
  }
  return Promise.reject(error);
});

onMounted(async () => {
   if (!isAuthenticated.value) {
    return;
  }
  console.log('authenticated', isAuthenticated.value);
  await fetchUserInfo();

  // load initial data
  // const userClaims = await getIdTokenClaims();
  // userStore.userId = userClaims?.sub || '';

  const accessTokenClaims = await getAccessTokenClaims(import.meta.env.VITE_LOGTO_RESOURCE);
  const companyId = accessTokenClaims?.companyId as string || '';

  await userStore.getLoggedInUser();
  await companyStore.getCompany(companyId);
})

</script>

<template>
  <DefaultLayoutWithVerticalNav v-bind="layoutAttrs"/>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/default-layout";
</style>
