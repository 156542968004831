import {defineStore} from 'pinia'
import {User} from "@/api/generated";
import {useUserStore} from "@/stores/userStore.ts";
import {useCompanyStore} from "@/stores/companyStore.ts";


export const useAuthStore = defineStore('auth', {
    state: () => {
        return {}
    },
    getters: {
        userId: () => {
            const userStore = useUserStore();
            return userStore.userId
        },
        companyId: () => {
            const companyStore = useCompanyStore();
            return companyStore.companyId
        },
        loggedInUser: () => {
            const userStore = useUserStore();
            return userStore.user;
        }
    },
    actions: {
        async getLoggedInUser(): Promise<User> {
            const usersStore = useUserStore();
            return usersStore.getLoggedInUser();
        },
    }
})
