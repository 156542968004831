import {Company, CompanyLegalFormEnum} from "@/api/generated";
import {companiesApiClient} from "@/api/clients.ts";
import {useAuthStore} from "@/stores/authStore.ts";

export const useCompanyStore = defineStore('company', {
    state: () => {
        return {
            company: {} as Company,
            legalFormItems: Object.keys(CompanyLegalFormEnum).map((key) => {
                return {
                    title: CompanyLegalFormEnum[key as keyof typeof CompanyLegalFormEnum],
                    value: key
                };
            })
        }
    },

    getters: {
        companyId: (state): string => state.company.id || ''
    },

    actions: {
        async getCompany(companyId: string): Promise<Company> {
            if (this.company.id) {
                return this.company;
            }
            this.company = (await companiesApiClient.getCompany(companyId)).data
            return this.company;
        },

        async patchCompany(companyLogo?: File): Promise<Company> {
            const authStore = useAuthStore();
            const response = (await companiesApiClient.patchCompany(authStore.companyId, companyLogo)).data;
            if (response) {
                this.company = response;
            }
            return this.getCompany(this.companyId);
        },

        async updateCompany(id: string, company: Company): Promise<Company> {
            const response = (await companiesApiClient.updateCompany(id, company)).data;
            if (response) {
                this.company = response;
            }
            return this.getCompany(this.companyId);
        }
    }
})
