<script setup lang="ts">
import {useLogto} from "@logto/vue";
import {singOutCallbackPath} from "@/logto";
import {useAuthStore} from "@/stores/authStore.ts";
import {storeToRefs} from "pinia";
import {useCompany} from "@/composables/useCompany.ts";

const {signOut} = useLogto();
const authStore = useAuthStore();
const {loggedInUser} = storeToRefs(authStore);

const {company} = useCompany();
</script>
<template>
  <VBadge
    dot
    bordered
    location="bottom right"
    offset-x="3"
    offset-y="3"
    color="success"
  >
    <VAvatar
      class="cursor-pointer"
      color="primary"
      variant="tonal"
    >
      <VImg v-if="loggedInUser?.avatar" :src="loggedInUser.avatar" />
      <!-- SECTION Menu -->
      <VMenu
        activator="parent"
        width="230"
        location="bottom end"
        offset="14px"
      >
        <VList>
          <!-- 👉 User Avatar & Name -->
          <VListItem>
            <template #prepend>
              <VListItemAction start>
                <VBadge
                  dot
                  location="bottom right"
                  offset-x="3"
                  offset-y="3"
                  color="success"
                >
                  <VAvatar
                    color="primary"
                    variant="tonal"
                  >
                    <VImg v-if="loggedInUser?.avatar" :src="loggedInUser.avatar" />
                  </VAvatar>
                </VBadge>
              </VListItemAction>
            </template>

            <VListItemTitle class="font-weight-semibold" v-if="loggedInUser">
              {{ loggedInUser.name }}
            </VListItemTitle>
            <VListItemSubtitle v-if="company">{{ company.name }}</VListItemSubtitle>
          </VListItem>

          <VDivider class="my-2" />

          <!-- 👉 Profile -->
          <VListItem link :to="{name: 'company-profile'}">
            <template #prepend>
              <VIcon
                class="me-2"
                icon="bx-user"
                size="22"
              />
            </template>

            <VListItemTitle>Profile</VListItemTitle>
          </VListItem>

          <!-- 👉 Settings -->
          <VListItem link :to="{name:'company-settings'}">
            <template #prepend>
              <VIcon
                class="me-2"
                icon="bx-cog"
                size="22"
              />
            </template>

            <VListItemTitle >Settings</VListItemTitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 Logout -->
          <VListItem :to="{name: 'company-login'}">
            <template #prepend>
              <VIcon
                class="me-2"
                icon="bx-log-out"
                size="22"
              />
            </template>

            <VListItemTitle @click="signOut(singOutCallbackPath)">Logout</VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
      <!-- !SECTION -->
    </VAvatar>
  </VBadge>
</template>
