import {createVuetify} from 'vuetify'
import {VBtn} from 'vuetify/components/VBtn'
import defaults from './defaults'
import theme from './theme'
import {de, en} from 'vuetify/locale'

// Styles
import '@core/scss/template/libs/vuetify/index.scss'
import 'vuetify/styles'

export default createVuetify({
    aliases: {
        IconBtn: VBtn,
    },
    defaults,
    theme,
    locale: {
        locale: 'de',
        fallback: 'en',
        messages: {de, en}
    }
})
