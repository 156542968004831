import {useCompanyStore} from "@/stores/companyStore.ts";
import {storeToRefs} from "pinia";
import {Company} from "@/api/generated";

export const useCompany = () => {
    const companyStore = useCompanyStore();
    const {company} = storeToRefs(companyStore);

    const isLoading = ref(false);

    const patchCompany = async (companyLogoFile?: File) => {
        try {
            isLoading.value = true;
            company.value = await companyStore.patchCompany(companyLogoFile);
            return company.value;
        } finally {
            isLoading.value = false;
        }
    };

    const updateCompany = async (c: Company) => {
        try {
            if (!company.value.id) {
                return
            }
            isLoading.value = true;
            company.value = await companyStore.updateCompany(c.id || '', c);
            return company.value;
        } finally {
            isLoading.value = false;
        }
    };

    return {
        isLoading,
        company,
        patchCompany,
        updateCompany
    }
}
