import {ErrorResponse} from "@/api/generated";

export const useErrorStore = defineStore('error', {
    state: () => {
        return {
            errors: [] as ErrorResponse[]
        }
    },
    actions: {
        addError(error: ErrorResponse) {
            this.errors.push(error)
        },

        clearErrors() {
            this.errors.length = 0;
        }
    }
})
