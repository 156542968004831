import {createI18n} from 'vue-i18n'

const messages = Object.fromEntries(
    Object.entries(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        import.meta.glob<{ default: any }>('./locales/*.json', {eager: true}))
        .map(([key, value]) => [key.slice(10, -5), value.default]),
)

export default createI18n({
    legacy: false,
    locale: 'de',
    fallbackLocale: 'en',
    messages,
    numberFormats: {
        'de': {
            currency: {
                style: 'currency', currency: 'EUR'
            },
            percent: {
                maximumFractionDigits: 2,
                useGrouping: true,
                style: 'percent'
            }
        }
    },
    datetimeFormats: {
        'de': {
            time: {
                hour: 'numeric', minute: 'numeric', hourCycle: 'h23'
            },
            datetime: {
                year: 'numeric', month: '2-digit', day: '2-digit',
                 hour: 'numeric', minute: 'numeric', hourCycle: 'h23'
            }
        }
    }
})
