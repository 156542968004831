import {setupLayouts} from 'virtual:generated-layouts'
import {createRouter, createWebHistory} from 'vue-router'
import routes from '~pages'
import {useLogto} from "@logto/vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: {
                name: 'company-dashboard'
            },
        },
        ...setupLayouts(routes),
    ],
})

router.beforeEach((to) => {
    const {isAuthenticated} = useLogto();
    console.warn('isAuthenticated', isAuthenticated.value, to.name);
    if (to.name === 'company-registration' && isAuthenticated.value) {
        return {name: 'company-dashboard'};
    }

    // if (to.meta.requiresAuth && !isAuthenticated.value) {
    //     return {name: 'Login'};
    // }

    return true;
})

export default router
