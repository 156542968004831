import {
    ApplicantApiFactory,
    AppointmentApiFactory,
    ArticleApiFactory,
    AttachmentApiFactory, BookingCalendarApiFactory,
    BookingRequestApiFactory, CompanyApiFactory,
    CustomerApiFactory,
    DogApiFactory,
    ErrorResponse,
    EventApiFactory, MessageApiFactory,
    PublicApiFactory,
    SettingsApiFactory,
    StripeApiFactory,
    UserApiFactory
} from "./generated";
import axios, {AxiosError} from 'axios';
import {useErrorStore} from "@/stores/errorStore.ts";

export const apiBaseUrl = import.meta.env.VITE_LOGTO_RESOURCE;
export const backendBaseUrl = import.meta.env.VITE_BACKEND_URL;

export const usersApiClient = UserApiFactory(undefined, apiBaseUrl, axios);
export const companiesApiClient = CompanyApiFactory(undefined, apiBaseUrl, axios);
export const articlesApiClient = ArticleApiFactory(undefined, apiBaseUrl, axios);
export const customersApiClient = CustomerApiFactory(undefined, apiBaseUrl, axios);
export const dogsApiClient = DogApiFactory(undefined, apiBaseUrl, axios);
export const eventsApiClient = EventApiFactory(undefined, apiBaseUrl, axios);
export const appointmentsApiClient = AppointmentApiFactory(undefined, apiBaseUrl, axios);
export const applicantsApiClient = ApplicantApiFactory(undefined, apiBaseUrl, axios);
export const attachmentsApiClient = AttachmentApiFactory(undefined, apiBaseUrl, axios);
export const publicApiClient = PublicApiFactory(undefined, apiBaseUrl, axios);
export const stripeApiClient = StripeApiFactory(undefined, apiBaseUrl, axios);
export const settingsApiClient = SettingsApiFactory(undefined, apiBaseUrl, axios);
export const bookingRequestApiClient = BookingRequestApiFactory(undefined, apiBaseUrl, axios);
export const bookingCalendarApiClient = BookingCalendarApiFactory(undefined, apiBaseUrl, axios);
export const messageApiClient = MessageApiFactory(undefined, apiBaseUrl, axios);

axios.interceptors.response.use(undefined,
    (error: any) => {
        const errorStore = useErrorStore();
        // TODO: create error store + error component
        if (error instanceof AxiosError) {
            errorStore.addError(error.response?.data as ErrorResponse);
        } else {
            errorStore.addError({errorMessage: 'An unknown error occurred', errorCode: 'UNKNOWN_ERROR'});
        }
        throw error;
    });
