import type { VerticalNavItems } from '@/@layouts/types'
import article from './article'
import customer from './customer'
import dog from './dog'
import event from "./event";

export default [
  {
    title: 'Dashboard',
    to: { name: 'company-dashboard' },
    icon: { icon: 'mdi-view-dashboard' },
    dataTestId: 'dashboard'
  },
  {
    title: 'Calendar',
    to: { name: 'company-calendar' },
    icon: { icon: 'mdi-calendar-multiselect' },
    dataTestId: 'calendar'
  },
    ...event,
    ...customer,
    ...article,
    ...dog,
  {
    title: 'BookingRequests',
    to: { name: 'company-booking-request-list' },
    icon: { icon: 'mdi-book' },
    badgeContent: '99+',
    dataTestId: 'booking-requests'
  },
  {
    title: 'BookingCalendar',
    to: { name: 'company-booking-calendar' },
    icon: { icon: 'mdi-calendar' },
    dataTestId: 'booking-calendar'
  }
] as VerticalNavItems
