import {createApp} from 'vue';
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import '@styles/styles.scss'
import App from './App.vue';
import {createLogto} from "@logto/vue";
import router from '@/router'
import '@core/scss/template/index.scss'
import vuetify from '@/plugins/vuetify'
import layoutsPlugin from '@/plugins/layouts'
import {config} from "@/logto";
import i18n from "@/plugins/i18n";


createApp(App)
    .use(createLogto, config)
    .use(router)
    .use(vuetify)
    .use(layoutsPlugin)
    .use(createPinia())
    .use(i18n)
    .mount('#app')
