/* tslint:disable */
/* eslint-disable */
/**
 * Wuffwizard API Definition
 * Description of the API for Wuffwizard APP. Used for Backend API and Frontend Client.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'formatted'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'mapUrl'?: string;
    /**
     * 
     * @type {AddressType}
     * @memberof Address
     */
    'type'?: AddressType;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'updated'?: string;
}


/**
 * 
 * @export
 * @interface AddressBase
 */
export interface AddressBase {
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'formatted'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'mapUrl'?: string;
    /**
     * 
     * @type {AddressType}
     * @memberof AddressBase
     */
    'type'?: AddressType;
    /**
     * 
     * @type {number}
     * @memberof AddressBase
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressBase
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBase
     */
    'updated'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AddressType = {
    Event: 'EVENT',
    Appointment: 'APPOINTMENT',
    Company: 'COMPANY',
    Customer: 'CUSTOMER'
} as const;

export type AddressType = typeof AddressType[keyof typeof AddressType];


/**
 * 
 * @export
 * @interface Applicant
 */
export interface Applicant {
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'appointmentId'?: string;
    /**
     * 
     * @type {BaseApplicantAppointment}
     * @memberof Applicant
     */
    'appointment'?: BaseApplicantAppointment;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'customerId'?: string;
    /**
     * 
     * @type {Customer}
     * @memberof Applicant
     */
    'customer'?: Customer;
    /**
     * 
     * @type {BaseApplicantApplicant}
     * @memberof Applicant
     */
    'applicant'?: BaseApplicantApplicant;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'dogId'?: string;
    /**
     * 
     * @type {BaseApplicantDog}
     * @memberof Applicant
     */
    'dog'?: BaseApplicantDog;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'status'?: ApplicantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'updated'?: string;
}

export const ApplicantStatusEnum = {
    Pending: 'PENDING',
    Participate: 'PARTICIPATE',
    Cancel: 'CANCEL',
    Waitlist: 'WAITLIST',
    NoShow: 'NO_SHOW'
} as const;

export type ApplicantStatusEnum = typeof ApplicantStatusEnum[keyof typeof ApplicantStatusEnum];

/**
 * 
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'endDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'eventId': string;
    /**
     * 
     * @type {Event}
     * @memberof Appointment
     */
    'event'?: Event;
    /**
     * 
     * @type {Array<string>}
     * @memberof Appointment
     */
    'applicants'?: Array<string>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Appointment
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {Address}
     * @memberof Appointment
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    'updated'?: string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    'updateSequence'?: number;
}
/**
 * 
 * @export
 * @interface AppointmentMessage
 */
export interface AppointmentMessage {
    /**
     * 
     * @type {any}
     * @memberof AppointmentMessage
     */
    'appointmentId'?: any;
    /**
     * 
     * @type {string}
     * @memberof AppointmentMessage
     */
    'id'?: string;
    /**
     * The message id from the external messaging service
     * @type {string}
     * @memberof AppointmentMessage
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentMessage
     */
    'companyId'?: string;
    /**
     * UserId of the sender
     * @type {string}
     * @memberof AppointmentMessage
     */
    'authorId'?: string;
    /**
     * List of CustomerIds
     * @type {Array<any>}
     * @memberof AppointmentMessage
     */
    'recipientIds': Array<any>;
    /**
     * The subject of the message, must be between 1 and 255 characters
     * @type {string}
     * @memberof AppointmentMessage
     */
    'subject': string;
    /**
     * The content of the message, must be at least 1 character
     * @type {string}
     * @memberof AppointmentMessage
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentMessage
     */
    'status'?: AppointmentMessageStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AppointmentMessage
     */
    'sentAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentMessage
     */
    'readAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentMessage
     */
    'created'?: string;
}

export const AppointmentMessageStatusEnum = {
    Pending: 'PENDING',
    Sent: 'SENT',
    Delivered: 'DELIVERED',
    Read: 'READ',
    Failed: 'FAILED'
} as const;

export type AppointmentMessageStatusEnum = typeof AppointmentMessageStatusEnum[keyof typeof AppointmentMessageStatusEnum];

/**
 * 
 * @export
 * @interface AppointmentsPaged
 */
export interface AppointmentsPaged {
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof AppointmentsPaged
     */
    'data': Array<Appointment>;
    /**
     * 
     * @type {number}
     * @memberof AppointmentsPaged
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentsPaged
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'unit': string;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'netPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'grossPrice': number;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'taxRate'?: number;
    /**
     * 
     * @type {ArticleCategory}
     * @memberof Article
     */
    'category': ArticleCategory;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'durationInMinutes'?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'updated'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleCategory = {
    Course: 'COURSE',
    GroupTraining: 'GROUP_TRAINING',
    SingleTraining: 'SINGLE_TRAINING',
    Webinar: 'WEBINAR',
    Coaching: 'COACHING',
    TravelCosts: 'TRAVEL_COSTS',
    Special: 'SPECIAL'
} as const;

export type ArticleCategory = typeof ArticleCategory[keyof typeof ArticleCategory];


/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'fileKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface AttachmentsPostRequest
 */
export interface AttachmentsPostRequest {
    /**
     * 
     * @type {Base64File}
     * @memberof AttachmentsPostRequest
     */
    'file'?: Base64File;
    /**
     * 
     * @type {string}
     * @memberof AttachmentsPostRequest
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentsPostRequest
     */
    'entityId'?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof AttachmentsPostRequest
     */
    'entityType'?: EntityType;
}


/**
 * 
 * @export
 * @interface Base64File
 */
export interface Base64File {
    /**
     * 
     * @type {string}
     * @memberof Base64File
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof Base64File
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface BaseApplicant
 */
export interface BaseApplicant {
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'appointmentId'?: string;
    /**
     * 
     * @type {BaseApplicantAppointment}
     * @memberof BaseApplicant
     */
    'appointment'?: BaseApplicantAppointment;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'customerId'?: string;
    /**
     * 
     * @type {Customer}
     * @memberof BaseApplicant
     */
    'customer'?: Customer;
    /**
     * 
     * @type {BaseApplicantApplicant}
     * @memberof BaseApplicant
     */
    'applicant'?: BaseApplicantApplicant;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'dogId'?: string;
    /**
     * 
     * @type {BaseApplicantDog}
     * @memberof BaseApplicant
     */
    'dog'?: BaseApplicantDog;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'status'?: BaseApplicantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicant
     */
    'updated'?: string;
}

export const BaseApplicantStatusEnum = {
    Pending: 'PENDING',
    Participate: 'PARTICIPATE',
    Cancel: 'CANCEL',
    Waitlist: 'WAITLIST',
    NoShow: 'NO_SHOW'
} as const;

export type BaseApplicantStatusEnum = typeof BaseApplicantStatusEnum[keyof typeof BaseApplicantStatusEnum];

/**
 * 
 * @export
 * @interface BaseApplicantApplicant
 */
export interface BaseApplicantApplicant {
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'companyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseApplicantApplicant
     */
    'customerNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'email': string;
    /**
     * 
     * @type {Address}
     * @memberof BaseApplicantApplicant
     */
    'address': Address;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'status'?: BaseApplicantApplicantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantApplicant
     */
    'updated'?: string;
}

export const BaseApplicantApplicantStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type BaseApplicantApplicantStatusEnum = typeof BaseApplicantApplicantStatusEnum[keyof typeof BaseApplicantApplicantStatusEnum];

/**
 * 
 * @export
 * @interface BaseApplicantAppointment
 */
export interface BaseApplicantAppointment {
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'endDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'eventId': string;
    /**
     * 
     * @type {Event}
     * @memberof BaseApplicantAppointment
     */
    'event'?: Event;
    /**
     * 
     * @type {Array<any>}
     * @memberof BaseApplicantAppointment
     */
    'applicants'?: Array<any>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof BaseApplicantAppointment
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {Address}
     * @memberof BaseApplicantAppointment
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantAppointment
     */
    'updated'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseApplicantAppointment
     */
    'updateSequence'?: number;
}
/**
 * 
 * @export
 * @interface BaseApplicantDog
 */
export interface BaseApplicantDog {
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof BaseApplicantDog
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'imageKey'?: string;
    /**
     * 
     * @type {Base64File}
     * @memberof BaseApplicantDog
     */
    'imageFile'?: Base64File;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'breed'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'chipNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'sex': BaseApplicantDogSexEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BaseApplicantDog
     */
    'neutered': boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof BaseApplicantDog
     */
    'customFields'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof BaseApplicantDog
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseApplicantDog
     */
    'updated'?: string;
}

export const BaseApplicantDogSexEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type BaseApplicantDogSexEnum = typeof BaseApplicantDogSexEnum[keyof typeof BaseApplicantDogSexEnum];

/**
 * 
 * @export
 * @interface BookingCalendar
 */
export interface BookingCalendar {
    /**
     * 
     * @type {boolean}
     * @memberof BookingCalendar
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingCalendar
     */
    'bookableArticleIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingCalendar
     */
    'bookableTrainerIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BookingCalendar
     */
    'defaultDurationInMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingCalendar
     */
    'timeBetweenAppointmentsInMinutes'?: number;
    /**
     * 
     * @type {Array<BookingCalendarWeekdayTimeSlotsInner>}
     * @memberof BookingCalendar
     */
    'weekdayTimeSlots': Array<BookingCalendarWeekdayTimeSlotsInner>;
}
/**
 * 
 * @export
 * @interface BookingCalendarWeekdayTimeSlotsInner
 */
export interface BookingCalendarWeekdayTimeSlotsInner {
    /**
     * 
     * @type {boolean}
     * @memberof BookingCalendarWeekdayTimeSlotsInner
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookingCalendarWeekdayTimeSlotsInner
     */
    'weekday': BookingCalendarWeekdayTimeSlotsInnerWeekdayEnum;
    /**
     * 
     * @type {Array<BookingCalendarWeekdayTimeSlotsInnerTimeSlotsInner>}
     * @memberof BookingCalendarWeekdayTimeSlotsInner
     */
    'timeSlots': Array<BookingCalendarWeekdayTimeSlotsInnerTimeSlotsInner>;
}

export const BookingCalendarWeekdayTimeSlotsInnerWeekdayEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type BookingCalendarWeekdayTimeSlotsInnerWeekdayEnum = typeof BookingCalendarWeekdayTimeSlotsInnerWeekdayEnum[keyof typeof BookingCalendarWeekdayTimeSlotsInnerWeekdayEnum];

/**
 * 
 * @export
 * @interface BookingCalendarWeekdayTimeSlotsInnerTimeSlotsInner
 */
export interface BookingCalendarWeekdayTimeSlotsInnerTimeSlotsInner {
    /**
     * 
     * @type {string}
     * @memberof BookingCalendarWeekdayTimeSlotsInnerTimeSlotsInner
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof BookingCalendarWeekdayTimeSlotsInnerTimeSlotsInner
     */
    'end': string;
}
/**
 * Request to participate at an event
 * @export
 * @interface BookingRequest
 */
export interface BookingRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'status'?: BookingRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'trainerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'articleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'end'?: string;
    /**
     * 
     * @type {Customer}
     * @memberof BookingRequest
     */
    'customer': Customer;
    /**
     * 
     * @type {ArticleCategory}
     * @memberof BookingRequest
     */
    'articleCategory': ArticleCategory;
    /**
     * 
     * @type {BookingRequestDog}
     * @memberof BookingRequest
     */
    'dog': BookingRequestDog;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'eventId'?: string;
    /**
     * 
     * @type {Event}
     * @memberof BookingRequest
     */
    'event'?: Event;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingRequest
     */
    'appointmentIds'?: Array<string>;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof BookingRequest
     */
    'appointments'?: Array<Appointment>;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequest
     */
    'updated'?: string;
}

export const BookingRequestStatusEnum = {
    New: 'NEW'
} as const;

export type BookingRequestStatusEnum = typeof BookingRequestStatusEnum[keyof typeof BookingRequestStatusEnum];

/**
 * 
 * @export
 * @interface BookingRequestDog
 */
export interface BookingRequestDog {
    /**
     * 
     * @type {string}
     * @memberof BookingRequestDog
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequestDog
     */
    'breed'?: string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'extra_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legal_form'?: CompanyLegalFormEnum;
    /**
     * 
     * @type {Address}
     * @memberof Company
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'instagram'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'facebook'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'twitter'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'youtube'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'linkedin'?: string;
    /**
     * personal Tax ID of a person
     * @type {number}
     * @memberof Company
     */
    'taxId'?: number;
    /**
     * VAT ID of a company
     * @type {number}
     * @memberof Company
     */
    'vatId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'logoKey'?: string;
}

export const CompanyLegalFormEnum = {
    Gmbh: 'GMBH',
    Ag: 'AG',
    Kg: 'KG',
    Ohg: 'OHG',
    Gbr: 'GBR',
    Ek: 'EK'
} as const;

export type CompanyLegalFormEnum = typeof CompanyLegalFormEnum[keyof typeof CompanyLegalFormEnum];

/**
 * 
 * @export
 * @interface CreateCheckoutSession200Response
 */
export interface CreateCheckoutSession200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutSession200Response
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutSession200Response
     */
    'publicKey'?: string;
}
/**
 * 
 * @export
 * @interface CreateCheckoutSessionRequest
 */
export interface CreateCheckoutSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutSessionRequest
     */
    'priceId'?: string;
}
/**
 * 
 * @export
 * @interface CreateEvent
 */
export interface CreateEvent {
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'id'?: string;
    /**
     * 
     * @type {EventType}
     * @memberof CreateEvent
     */
    'type': EventType;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'status': CreateEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'availability'?: CreateEventAvailabilityEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateEvent
     */
    'maximumNumberOfParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEvent
     */
    'minimumNumberOfParticipants'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'notes'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEvent
     */
    'bookableOnline'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'dogId'?: string;
    /**
     * UserId of the trainer
     * @type {string}
     * @memberof CreateEvent
     */
    'trainerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateEvent
     */
    'articleIds': Array<string>;
    /**
     * 
     * @type {Address}
     * @memberof CreateEvent
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'imageKey'?: string;
    /**
     * 
     * @type {Base64File}
     * @memberof CreateEvent
     */
    'imageFile'?: Base64File;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'meetingLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'startDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'endDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'frequency': CreateEventFrequencyEnum;
    /**
     * Number of repetitions
     * @type {number}
     * @memberof CreateEvent
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvent
     */
    'updated'?: string;
}

export const CreateEventStatusEnum = {
    Draft: 'DRAFT',
    Published: 'PUBLISHED',
    Done: 'DONE',
    Canceled: 'CANCELED',
    Archived: 'ARCHIVED'
} as const;

export type CreateEventStatusEnum = typeof CreateEventStatusEnum[keyof typeof CreateEventStatusEnum];
export const CreateEventAvailabilityEnum = {
    Open: 'OPEN',
    HalfOpen: 'HALF_OPEN',
    Closed: 'CLOSED'
} as const;

export type CreateEventAvailabilityEnum = typeof CreateEventAvailabilityEnum[keyof typeof CreateEventAvailabilityEnum];
export const CreateEventFrequencyEnum = {
    Once: 'ONCE',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Yearly: 'YEARLY'
} as const;

export type CreateEventFrequencyEnum = typeof CreateEventFrequencyEnum[keyof typeof CreateEventFrequencyEnum];

/**
 * 
 * @export
 * @interface CreateUserWithCompany
 */
export interface CreateUserWithCompany {
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithCompany
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithCompany
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithCompany
     */
    'owner': string;
    /**
     * 
     * @type {Address}
     * @memberof CreateUserWithCompany
     */
    'address': Address;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithCompany
     */
    'type'?: CreateUserWithCompanyTypeEnum;
}

export const CreateUserWithCompanyTypeEnum = {
    Private: 'PRIVATE',
    Company: 'COMPANY'
} as const;

export type CreateUserWithCompanyTypeEnum = typeof CreateUserWithCompanyTypeEnum[keyof typeof CreateUserWithCompanyTypeEnum];

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'companyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'customerNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'email': string;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    'address': Address;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'status'?: CustomerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'updated'?: string;
}

export const CustomerStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CustomerStatusEnum = typeof CustomerStatusEnum[keyof typeof CustomerStatusEnum];

/**
 * New schema
 * @export
 * @interface Dog
 */
export interface Dog {
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof Dog
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'imageKey'?: string;
    /**
     * 
     * @type {Base64File}
     * @memberof Dog
     */
    'imageFile'?: Base64File;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'breed'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'chipNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'sex': DogSexEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Dog
     */
    'neutered': boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Dog
     */
    'customFields'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Dog
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dog
     */
    'updated'?: string;
}

export const DogSexEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type DogSexEnum = typeof DogSexEnum[keyof typeof DogSexEnum];

/**
 * EntityType enum
 * @export
 * @enum {string}
 */

export const EntityType = {
    Event: 'EVENT',
    Appointment: 'APPOINTMENT',
    Article: 'ARTICLE',
    User: 'USER',
    Company: 'COMPANY',
    Customer: 'CUSTOMER',
    Dog: 'DOG',
    Applicant: 'APPLICANT'
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'errorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'id'?: string;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    'type': EventType;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'status': EventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'availability'?: EventAvailabilityEnum;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'maximumNumberOfParticipants'?: number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'minimumNumberOfParticipants'?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'notes'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    'bookableOnline'?: boolean;
    /**
     * UserId of the trainer
     * @type {string}
     * @memberof Event
     */
    'trainerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Event
     */
    'articleIds': Array<string>;
    /**
     * 
     * @type {Address}
     * @memberof Event
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'imageKey'?: string;
    /**
     * 
     * @type {Base64File}
     * @memberof Event
     */
    'imageFile'?: Base64File;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'meetingLink'?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Event
     */
    'attachments'?: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'updated'?: string;
}

export const EventStatusEnum = {
    Draft: 'DRAFT',
    Published: 'PUBLISHED',
    Done: 'DONE',
    Canceled: 'CANCELED',
    Archived: 'ARCHIVED',
    Requested: 'REQUESTED'
} as const;

export type EventStatusEnum = typeof EventStatusEnum[keyof typeof EventStatusEnum];
export const EventAvailabilityEnum = {
    Open: 'OPEN',
    HalfOpen: 'HALF_OPEN',
    Closed: 'CLOSED'
} as const;

export type EventAvailabilityEnum = typeof EventAvailabilityEnum[keyof typeof EventAvailabilityEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const EventType = {
    Course: 'COURSE',
    GroupTraining: 'GROUP_TRAINING',
    SingleTraining: 'SINGLE_TRAINING',
    Webinar: 'WEBINAR',
    Coaching: 'COACHING',
    Special: 'SPECIAL'
} as const;

export type EventType = typeof EventType[keyof typeof EventType];


/**
 * 
 * @export
 * @interface EventsPaged
 */
export interface EventsPaged {
    /**
     * 
     * @type {Array<Event>}
     * @memberof EventsPaged
     */
    'data': Array<Event>;
    /**
     * 
     * @type {number}
     * @memberof EventsPaged
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof EventsPaged
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetAddresses200Response
 */
export interface GetAddresses200Response {
    /**
     * 
     * @type {Array<Address>}
     * @memberof GetAddresses200Response
     */
    'data': Array<Address>;
    /**
     * 
     * @type {number}
     * @memberof GetAddresses200Response
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GetAddresses200Response
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetApplicants200Response
 */
export interface GetApplicants200Response {
    /**
     * 
     * @type {Array<Applicant>}
     * @memberof GetApplicants200Response
     */
    'data': Array<Applicant>;
    /**
     * 
     * @type {number}
     * @memberof GetApplicants200Response
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GetApplicants200Response
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetArticles200Response
 */
export interface GetArticles200Response {
    /**
     * 
     * @type {Array<Article>}
     * @memberof GetArticles200Response
     */
    'data': Array<Article>;
    /**
     * 
     * @type {number}
     * @memberof GetArticles200Response
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GetArticles200Response
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetBookingCalendarPublic200Response
 */
export interface GetBookingCalendarPublic200Response {
    /**
     * 
     * @type {Array<Article>}
     * @memberof GetBookingCalendarPublic200Response
     */
    'articles'?: Array<Article>;
    /**
     * 
     * @type {Array<User>}
     * @memberof GetBookingCalendarPublic200Response
     */
    'trainers'?: Array<User>;
}
/**
 * 
 * @export
 * @interface GetBookingCalendarTimeSlotsPublic200Response
 */
export interface GetBookingCalendarTimeSlotsPublic200Response {
    /**
     * 
     * @type {Array<GetBookingCalendarTimeSlotsPublic200ResponseTimeSlotsInner>}
     * @memberof GetBookingCalendarTimeSlotsPublic200Response
     */
    'timeSlots': Array<GetBookingCalendarTimeSlotsPublic200ResponseTimeSlotsInner>;
}
/**
 * 
 * @export
 * @interface GetBookingCalendarTimeSlotsPublic200ResponseTimeSlotsInner
 */
export interface GetBookingCalendarTimeSlotsPublic200ResponseTimeSlotsInner {
    /**
     * 
     * @type {string}
     * @memberof GetBookingCalendarTimeSlotsPublic200ResponseTimeSlotsInner
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof GetBookingCalendarTimeSlotsPublic200ResponseTimeSlotsInner
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface GetBookingRequests200Response
 */
export interface GetBookingRequests200Response {
    /**
     * 
     * @type {Array<BookingRequest>}
     * @memberof GetBookingRequests200Response
     */
    'data': Array<BookingRequest>;
    /**
     * 
     * @type {number}
     * @memberof GetBookingRequests200Response
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GetBookingRequests200Response
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetCustomers200Response
 */
export interface GetCustomers200Response {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof GetCustomers200Response
     */
    'data': Array<Customer>;
    /**
     * 
     * @type {number}
     * @memberof GetCustomers200Response
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GetCustomers200Response
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetDogs200Response
 */
export interface GetDogs200Response {
    /**
     * 
     * @type {Array<Dog>}
     * @memberof GetDogs200Response
     */
    'data': Array<Dog>;
    /**
     * 
     * @type {number}
     * @memberof GetDogs200Response
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GetDogs200Response
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetMessages200Response
 */
export interface GetMessages200Response {
    /**
     * 
     * @type {Array<Message>}
     * @memberof GetMessages200Response
     */
    'data': Array<Message>;
    /**
     * 
     * @type {number}
     * @memberof GetMessages200Response
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GetMessages200Response
     */
    'totalElements': number;
}
/**
 * 
 * @export
 * @interface GetSessionStatus200Response
 */
export interface GetSessionStatus200Response {
    /**
     * 
     * @type {string}
     * @memberof GetSessionStatus200Response
     */
    'sessionId'?: string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'id'?: string;
    /**
     * The message id from the external messaging service
     * @type {string}
     * @memberof Message
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'companyId'?: string;
    /**
     * UserId of the sender
     * @type {string}
     * @memberof Message
     */
    'authorId'?: string;
    /**
     * List of CustomerIds
     * @type {Array<string>}
     * @memberof Message
     */
    'recipientIds': Array<string>;
    /**
     * The subject of the message, must be between 1 and 255 characters
     * @type {string}
     * @memberof Message
     */
    'subject': string;
    /**
     * The content of the message, must be at least 1 character
     * @type {string}
     * @memberof Message
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'status'?: MessageStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'sentAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'readAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'created'?: string;
}

export const MessageStatusEnum = {
    Pending: 'PENDING',
    Sent: 'SENT',
    Delivered: 'DELIVERED',
    Read: 'READ',
    Failed: 'FAILED'
} as const;

export type MessageStatusEnum = typeof MessageStatusEnum[keyof typeof MessageStatusEnum];

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'preferredUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'zoneinfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'locale'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    'address'?: Address;
}
/**
 * 
 * @export
 * @interface PublicApplicant
 */
export interface PublicApplicant {
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'eventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'appointmentId'?: string;
    /**
     * 
     * @type {BaseApplicantAppointment}
     * @memberof PublicApplicant
     */
    'appointment'?: BaseApplicantAppointment;
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'customerId'?: string;
    /**
     * 
     * @type {Customer}
     * @memberof PublicApplicant
     */
    'customer'?: Customer;
    /**
     * 
     * @type {BaseApplicantApplicant}
     * @memberof PublicApplicant
     */
    'applicant'?: BaseApplicantApplicant;
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'dogId'?: string;
    /**
     * 
     * @type {BaseApplicantDog}
     * @memberof PublicApplicant
     */
    'dog'?: BaseApplicantDog;
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'status'?: PublicApplicantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicApplicant
     */
    'updated'?: string;
}

export const PublicApplicantStatusEnum = {
    Pending: 'PENDING',
    Participate: 'PARTICIPATE',
    Cancel: 'CANCEL',
    Waitlist: 'WAITLIST',
    NoShow: 'NO_SHOW'
} as const;

export type PublicApplicantStatusEnum = typeof PublicApplicantStatusEnum[keyof typeof PublicApplicantStatusEnum];

/**
 * 
 * @export
 * @interface PublicSettings
 */
export interface PublicSettings {
    /**
     * Terms and conditions for the company
     * @type {string}
     * @memberof PublicSettings
     */
    'termsConditions'?: string;
    /**
     * Legal imprint of the company
     * @type {string}
     * @memberof PublicSettings
     */
    'imprint'?: string;
    /**
     * Number of days until payment is due
     * @type {number}
     * @memberof PublicSettings
     */
    'paymentTerms'?: number;
    /**
     * International Bank Account Number
     * @type {string}
     * @memberof PublicSettings
     */
    'iban'?: string;
    /**
     * Bank Identifier Code
     * @type {string}
     * @memberof PublicSettings
     */
    'bic'?: string;
}
/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * Public key for Stripe integration
     * @type {string}
     * @memberof Settings
     */
    'stripePublicKey'?: string;
    /**
     * API key for Stripe integration
     * @type {string}
     * @memberof Settings
     */
    'stripeApiKey'?: string;
    /**
     * Current customer number counter
     * @type {number}
     * @memberof Settings
     */
    'customerNumberCounter'?: number;
    /**
     * Terms and conditions for the company
     * @type {string}
     * @memberof Settings
     */
    'termsConditions'?: string;
    /**
     * Legal imprint of the company
     * @type {string}
     * @memberof Settings
     */
    'imprint'?: string;
    /**
     * Number of days until payment is due
     * @type {number}
     * @memberof Settings
     */
    'paymentTerms'?: number;
    /**
     * International Bank Account Number
     * @type {string}
     * @memberof Settings
     */
    'iban'?: string;
    /**
     * Bank Identifier Code
     * @type {string}
     * @memberof Settings
     */
    'bic'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'primaryEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'primaryPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar'?: string;
    /**
     * 
     * @type {UserCustomData}
     * @memberof User
     */
    'customData'?: UserCustomData;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'lastSignInAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {Profile}
     * @memberof User
     */
    'profile'?: Profile;
}
/**
 * 
 * @export
 * @interface UserCustomData
 */
export interface UserCustomData {
    /**
     * 
     * @type {string}
     * @memberof UserCustomData
     */
    'companyId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCustomData
     */
    'isTrainer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCustomData
     */
    'type'?: UserCustomDataTypeEnum;
}

export const UserCustomDataTypeEnum = {
    Private: 'PRIVATE',
    Company: 'COMPANY'
} as const;

export type UserCustomDataTypeEnum = typeof UserCustomDataTypeEnum[keyof typeof UserCustomDataTypeEnum];


/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create address
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddress: async (address: Address, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            assertParamExists('createAddress', 'address', address)
            const localVarPath = `/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete address by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAddress', 'id', id)
            const localVarPath = `/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get address by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAddress', 'id', id)
            const localVarPath = `/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all addresses
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetAddressesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {AddressType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddresses: async (page?: number, size?: number, order?: GetAddressesOrderEnum, sort?: string, type?: AddressType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update address by id
         * @param {string} id 
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress: async (id: string, address: Address, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAddress', 'id', id)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('updateAddress', 'address', address)
            const localVarPath = `/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create address
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddress(address: Address, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAddress(address, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.createAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete address by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddress(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAddress(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.deleteAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get address by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddress(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddress(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.getAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all addresses
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetAddressesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {AddressType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddresses(page?: number, size?: number, order?: GetAddressesOrderEnum, sort?: string, type?: AddressType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddresses200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddresses(page, size, order, sort, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.getAddresses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update address by id
         * @param {string} id 
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddress(id: string, address: Address, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAddress(id, address, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressApi.updateAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @summary Create address
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddress(address: Address, options?: any): AxiosPromise<Address> {
            return localVarFp.createAddress(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete address by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAddress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get address by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddress(id: string, options?: any): AxiosPromise<Address> {
            return localVarFp.getAddress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all addresses
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetAddressesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {AddressType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddresses(page?: number, size?: number, order?: GetAddressesOrderEnum, sort?: string, type?: AddressType, options?: any): AxiosPromise<GetAddresses200Response> {
            return localVarFp.getAddresses(page, size, order, sort, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update address by id
         * @param {string} id 
         * @param {Address} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddress(id: string, address: Address, options?: any): AxiosPromise<Address> {
            return localVarFp.updateAddress(id, address, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @summary Create address
     * @param {Address} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public createAddress(address: Address, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).createAddress(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete address by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public deleteAddress(id: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).deleteAddress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get address by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getAddress(id: string, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).getAddress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all addresses
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetAddressesOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {AddressType} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getAddresses(page?: number, size?: number, order?: GetAddressesOrderEnum, sort?: string, type?: AddressType, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).getAddresses(page, size, order, sort, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update address by id
     * @param {string} id 
     * @param {Address} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public updateAddress(id: string, address: Address, options?: RawAxiosRequestConfig) {
        return AddressApiFp(this.configuration).updateAddress(id, address, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAddressesOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetAddressesOrderEnum = typeof GetAddressesOrderEnum[keyof typeof GetAddressesOrderEnum];


/**
 * ApplicantApi - axios parameter creator
 * @export
 */
export const ApplicantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add applicant to appointment
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicant: async (applicant: Applicant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicant' is not null or undefined
            assertParamExists('addApplicant', 'applicant', applicant)
            const localVarPath = `/applicants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove applicant from appointment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicant: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicant', 'id', id)
            const localVarPath = `/applicants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get applicant by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicant: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicant', 'id', id)
            const localVarPath = `/applicants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all applicants
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetApplicantsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [eventId] 
         * @param {string} [appointmentId] 
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicants: async (page?: number, size?: number, order?: GetApplicantsOrderEnum, sort?: string, eventId?: string, appointmentId?: string, customerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/applicants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update applicant by id
         * @param {string} id 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicant: async (id: string, applicant: Applicant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicant', 'id', id)
            // verify required parameter 'applicant' is not null or undefined
            assertParamExists('updateApplicant', 'applicant', applicant)
            const localVarPath = `/applicants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicantApi - functional programming interface
 * @export
 */
export const ApplicantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add applicant to appointment
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApplicant(applicant: Applicant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Applicant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApplicant(applicant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicantApi.addApplicant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove applicant from appointment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicant(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicant(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicantApi.deleteApplicant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get applicant by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicant(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Applicant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicant(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicantApi.getApplicant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all applicants
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetApplicantsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [eventId] 
         * @param {string} [appointmentId] 
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicants(page?: number, size?: number, order?: GetApplicantsOrderEnum, sort?: string, eventId?: string, appointmentId?: string, customerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApplicants200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicants(page, size, order, sort, eventId, appointmentId, customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicantApi.getApplicants']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update applicant by id
         * @param {string} id 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicant(id: string, applicant: Applicant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Applicant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicant(id, applicant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicantApi.updateApplicant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApplicantApi - factory interface
 * @export
 */
export const ApplicantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicantApiFp(configuration)
    return {
        /**
         * 
         * @summary Add applicant to appointment
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicant(applicant: Applicant, options?: any): AxiosPromise<Applicant> {
            return localVarFp.addApplicant(applicant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove applicant from appointment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicant(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApplicant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get applicant by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicant(id: string, options?: any): AxiosPromise<Applicant> {
            return localVarFp.getApplicant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all applicants
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetApplicantsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [eventId] 
         * @param {string} [appointmentId] 
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicants(page?: number, size?: number, order?: GetApplicantsOrderEnum, sort?: string, eventId?: string, appointmentId?: string, customerId?: string, options?: any): AxiosPromise<GetApplicants200Response> {
            return localVarFp.getApplicants(page, size, order, sort, eventId, appointmentId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update applicant by id
         * @param {string} id 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicant(id: string, applicant: Applicant, options?: any): AxiosPromise<Applicant> {
            return localVarFp.updateApplicant(id, applicant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicantApi - object-oriented interface
 * @export
 * @class ApplicantApi
 * @extends {BaseAPI}
 */
export class ApplicantApi extends BaseAPI {
    /**
     * 
     * @summary Add applicant to appointment
     * @param {Applicant} applicant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantApi
     */
    public addApplicant(applicant: Applicant, options?: RawAxiosRequestConfig) {
        return ApplicantApiFp(this.configuration).addApplicant(applicant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove applicant from appointment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantApi
     */
    public deleteApplicant(id: string, options?: RawAxiosRequestConfig) {
        return ApplicantApiFp(this.configuration).deleteApplicant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get applicant by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantApi
     */
    public getApplicant(id: string, options?: RawAxiosRequestConfig) {
        return ApplicantApiFp(this.configuration).getApplicant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all applicants
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetApplicantsOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {string} [eventId] 
     * @param {string} [appointmentId] 
     * @param {string} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantApi
     */
    public getApplicants(page?: number, size?: number, order?: GetApplicantsOrderEnum, sort?: string, eventId?: string, appointmentId?: string, customerId?: string, options?: RawAxiosRequestConfig) {
        return ApplicantApiFp(this.configuration).getApplicants(page, size, order, sort, eventId, appointmentId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update applicant by id
     * @param {string} id 
     * @param {Applicant} applicant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantApi
     */
    public updateApplicant(id: string, applicant: Applicant, options?: RawAxiosRequestConfig) {
        return ApplicantApiFp(this.configuration).updateApplicant(id, applicant, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetApplicantsOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetApplicantsOrderEnum = typeof GetApplicantsOrderEnum[keyof typeof GetApplicantsOrderEnum];


/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create appointment
         * @param {Appointment} appointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment: async (appointment: Appointment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointment' is not null or undefined
            assertParamExists('createAppointment', 'appointment', appointment)
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete appointment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppointment: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAppointment', 'id', id)
            const localVarPath = `/appointments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get appointment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAppointment', 'id', id)
            const localVarPath = `/appointments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all appointments
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetAppointmentsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [eventId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments: async (page?: number, size?: number, order?: GetAppointmentsOrderEnum, sort?: string, eventId?: string, startDateTime?: string, endDateTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = (startDateTime as any instanceof Date) ?
                    (startDateTime as any).toISOString() :
                    startDateTime;
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = (endDateTime as any instanceof Date) ?
                    (endDateTime as any).toISOString() :
                    endDateTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update appointment by id
         * @param {string} id 
         * @param {Appointment} appointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment: async (id: string, appointment: Appointment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAppointment', 'id', id)
            // verify required parameter 'appointment' is not null or undefined
            assertParamExists('updateAppointment', 'appointment', appointment)
            const localVarPath = `/appointments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create appointment
         * @param {Appointment} appointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppointment(appointment: Appointment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppointment(appointment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.createAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete appointment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppointment(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppointment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.deleteAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get appointment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointment(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all appointments
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetAppointmentsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [eventId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointments(page?: number, size?: number, order?: GetAppointmentsOrderEnum, sort?: string, eventId?: string, startDateTime?: string, endDateTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentsPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointments(page, size, order, sort, eventId, startDateTime, endDateTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.getAppointments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update appointment by id
         * @param {string} id 
         * @param {Appointment} appointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointment(id: string, appointment: Appointment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Appointment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointment(id, appointment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentApi.updateAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create appointment
         * @param {Appointment} appointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment(appointment: Appointment, options?: any): AxiosPromise<Appointment> {
            return localVarFp.createAppointment(appointment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete appointment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppointment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get appointment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment(id: string, options?: any): AxiosPromise<Appointment> {
            return localVarFp.getAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all appointments
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetAppointmentsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [eventId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments(page?: number, size?: number, order?: GetAppointmentsOrderEnum, sort?: string, eventId?: string, startDateTime?: string, endDateTime?: string, options?: any): AxiosPromise<AppointmentsPaged> {
            return localVarFp.getAppointments(page, size, order, sort, eventId, startDateTime, endDateTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update appointment by id
         * @param {string} id 
         * @param {Appointment} appointment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment(id: string, appointment: Appointment, options?: any): AxiosPromise<Appointment> {
            return localVarFp.updateAppointment(id, appointment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
    /**
     * 
     * @summary Create appointment
     * @param {Appointment} appointment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public createAppointment(appointment: Appointment, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).createAppointment(appointment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete appointment by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public deleteAppointment(id: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).deleteAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get appointment by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointment(id: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all appointments
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetAppointmentsOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {string} [eventId] 
     * @param {string} [startDateTime] 
     * @param {string} [endDateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointments(page?: number, size?: number, order?: GetAppointmentsOrderEnum, sort?: string, eventId?: string, startDateTime?: string, endDateTime?: string, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointments(page, size, order, sort, eventId, startDateTime, endDateTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update appointment by id
     * @param {string} id 
     * @param {Appointment} appointment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateAppointment(id: string, appointment: Appointment, options?: RawAxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateAppointment(id, appointment, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppointmentsOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetAppointmentsOrderEnum = typeof GetAppointmentsOrderEnum[keyof typeof GetAppointmentsOrderEnum];


/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create article
         * @param {Article} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticle: async (article: Article, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('createArticle', 'article', article)
            const localVarPath = `/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(article, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete article by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteArticle', 'id', id)
            const localVarPath = `/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get article by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getArticle', 'id', id)
            const localVarPath = `/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all articles
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetArticlesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {ArticleCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticles: async (page?: number, size?: number, order?: GetArticlesOrderEnum, sort?: string, category?: ArticleCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update article by id
         * @param {string} id 
         * @param {Article} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle: async (id: string, article: Article, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateArticle', 'id', id)
            // verify required parameter 'article' is not null or undefined
            assertParamExists('updateArticle', 'article', article)
            const localVarPath = `/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(article, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create article
         * @param {Article} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArticle(article: Article, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createArticle(article, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.createArticle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete article by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArticle(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticle(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.deleteArticle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get article by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticle(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticle(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.getArticle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all articles
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetArticlesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {ArticleCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticles(page?: number, size?: number, order?: GetArticlesOrderEnum, sort?: string, category?: ArticleCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetArticles200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticles(page, size, order, sort, category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.getArticles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update article by id
         * @param {string} id 
         * @param {Article} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArticle(id: string, article: Article, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticle(id, article, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.updateArticle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @summary Create article
         * @param {Article} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticle(article: Article, options?: any): AxiosPromise<Article> {
            return localVarFp.createArticle(article, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete article by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteArticle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get article by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(id: string, options?: any): AxiosPromise<Article> {
            return localVarFp.getArticle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all articles
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetArticlesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {ArticleCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticles(page?: number, size?: number, order?: GetArticlesOrderEnum, sort?: string, category?: ArticleCategory, options?: any): AxiosPromise<GetArticles200Response> {
            return localVarFp.getArticles(page, size, order, sort, category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update article by id
         * @param {string} id 
         * @param {Article} article 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle(id: string, article: Article, options?: any): AxiosPromise<Article> {
            return localVarFp.updateArticle(id, article, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @summary Create article
     * @param {Article} article 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public createArticle(article: Article, options?: RawAxiosRequestConfig) {
        return ArticleApiFp(this.configuration).createArticle(article, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete article by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public deleteArticle(id: string, options?: RawAxiosRequestConfig) {
        return ArticleApiFp(this.configuration).deleteArticle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get article by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticle(id: string, options?: RawAxiosRequestConfig) {
        return ArticleApiFp(this.configuration).getArticle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all articles
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetArticlesOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {ArticleCategory} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticles(page?: number, size?: number, order?: GetArticlesOrderEnum, sort?: string, category?: ArticleCategory, options?: RawAxiosRequestConfig) {
        return ArticleApiFp(this.configuration).getArticles(page, size, order, sort, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update article by id
     * @param {string} id 
     * @param {Article} article 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public updateArticle(id: string, article: Article, options?: RawAxiosRequestConfig) {
        return ArticleApiFp(this.configuration).updateArticle(id, article, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetArticlesOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetArticlesOrderEnum = typeof GetArticlesOrderEnum[keyof typeof GetArticlesOrderEnum];


/**
 * AttachmentApi - axios parameter creator
 * @export
 */
export const AttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentsIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachmentsIdDelete', 'id', id)
            const localVarPath = `/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachmentsIdGet', 'id', id)
            const localVarPath = `/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add attachment to given entity type and id
         * @param {AttachmentsPostRequest} attachmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentsPost: async (attachmentsPostRequest: AttachmentsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentsPostRequest' is not null or undefined
            assertParamExists('attachmentsPost', 'attachmentsPostRequest', attachmentsPostRequest)
            const localVarPath = `/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachmentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentsIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentsIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AttachmentApi.attachmentsIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentsIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AttachmentApi.attachmentsIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add attachment to given entity type and id
         * @param {AttachmentsPostRequest} attachmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachmentsPost(attachmentsPostRequest: AttachmentsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachmentsPost(attachmentsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AttachmentApi.attachmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.attachmentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentsIdGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.attachmentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add attachment to given entity type and id
         * @param {AttachmentsPostRequest} attachmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachmentsPost(attachmentsPostRequest: AttachmentsPostRequest, options?: any): AxiosPromise<Attachment> {
            return localVarFp.attachmentsPost(attachmentsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * 
     * @summary Delete attachment by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentsIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get attachment by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentsIdGet(id: string, options?: RawAxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add attachment to given entity type and id
     * @param {AttachmentsPostRequest} attachmentsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public attachmentsPost(attachmentsPostRequest: AttachmentsPostRequest, options?: RawAxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).attachmentsPost(attachmentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BookingCalendarApi - axios parameter creator
 * @export
 */
export const BookingCalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get company booking calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCalendar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/booking-calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put booking calendar
         * @param {BookingCalendar} bookingCalendar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookingCalendar: async (bookingCalendar: BookingCalendar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingCalendar' is not null or undefined
            assertParamExists('updateBookingCalendar', 'bookingCalendar', bookingCalendar)
            const localVarPath = `/booking-calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingCalendar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingCalendarApi - functional programming interface
 * @export
 */
export const BookingCalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingCalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get company booking calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingCalendar(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingCalendar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingCalendar(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingCalendarApi.getBookingCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Put booking calendar
         * @param {BookingCalendar} bookingCalendar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBookingCalendar(bookingCalendar: BookingCalendar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingCalendar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBookingCalendar(bookingCalendar, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingCalendarApi.updateBookingCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BookingCalendarApi - factory interface
 * @export
 */
export const BookingCalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingCalendarApiFp(configuration)
    return {
        /**
         * 
         * @summary Get company booking calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCalendar(options?: any): AxiosPromise<BookingCalendar> {
            return localVarFp.getBookingCalendar(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put booking calendar
         * @param {BookingCalendar} bookingCalendar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookingCalendar(bookingCalendar: BookingCalendar, options?: any): AxiosPromise<BookingCalendar> {
            return localVarFp.updateBookingCalendar(bookingCalendar, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingCalendarApi - object-oriented interface
 * @export
 * @class BookingCalendarApi
 * @extends {BaseAPI}
 */
export class BookingCalendarApi extends BaseAPI {
    /**
     * 
     * @summary Get company booking calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingCalendarApi
     */
    public getBookingCalendar(options?: RawAxiosRequestConfig) {
        return BookingCalendarApiFp(this.configuration).getBookingCalendar(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put booking calendar
     * @param {BookingCalendar} bookingCalendar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingCalendarApi
     */
    public updateBookingCalendar(bookingCalendar: BookingCalendar, options?: RawAxiosRequestConfig) {
        return BookingCalendarApiFp(this.configuration).updateBookingCalendar(bookingCalendar, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BookingRequestApi - axios parameter creator
 * @export
 */
export const BookingRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete booking request by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookingRequest: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBookingRequest', 'id', id)
            const localVarPath = `/booking-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get booking request by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingRequest: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBookingRequest', 'id', id)
            const localVarPath = `/booking-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all booking requests
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetBookingRequestsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingRequests: async (page?: number, size?: number, order?: GetBookingRequestsOrderEnum, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/booking-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update booking request by id
         * @param {string} id 
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookingRequest: async (id: string, bookingRequest: BookingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBookingRequest', 'id', id)
            // verify required parameter 'bookingRequest' is not null or undefined
            assertParamExists('updateBookingRequest', 'bookingRequest', bookingRequest)
            const localVarPath = `/booking-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingRequestApi - functional programming interface
 * @export
 */
export const BookingRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete booking request by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBookingRequest(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBookingRequest(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingRequestApi.deleteBookingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get booking request by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingRequest(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingRequest(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingRequestApi.getBookingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all booking requests
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetBookingRequestsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingRequests(page?: number, size?: number, order?: GetBookingRequestsOrderEnum, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBookingRequests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingRequests(page, size, order, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingRequestApi.getBookingRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update booking request by id
         * @param {string} id 
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBookingRequest(id: string, bookingRequest: BookingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBookingRequest(id, bookingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingRequestApi.updateBookingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BookingRequestApi - factory interface
 * @export
 */
export const BookingRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingRequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete booking request by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookingRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBookingRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get booking request by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingRequest(id: string, options?: any): AxiosPromise<BookingRequest> {
            return localVarFp.getBookingRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all booking requests
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetBookingRequestsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingRequests(page?: number, size?: number, order?: GetBookingRequestsOrderEnum, sort?: string, options?: any): AxiosPromise<GetBookingRequests200Response> {
            return localVarFp.getBookingRequests(page, size, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update booking request by id
         * @param {string} id 
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBookingRequest(id: string, bookingRequest: BookingRequest, options?: any): AxiosPromise<BookingRequest> {
            return localVarFp.updateBookingRequest(id, bookingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingRequestApi - object-oriented interface
 * @export
 * @class BookingRequestApi
 * @extends {BaseAPI}
 */
export class BookingRequestApi extends BaseAPI {
    /**
     * 
     * @summary Delete booking request by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingRequestApi
     */
    public deleteBookingRequest(id: string, options?: RawAxiosRequestConfig) {
        return BookingRequestApiFp(this.configuration).deleteBookingRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get booking request by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingRequestApi
     */
    public getBookingRequest(id: string, options?: RawAxiosRequestConfig) {
        return BookingRequestApiFp(this.configuration).getBookingRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all booking requests
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetBookingRequestsOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingRequestApi
     */
    public getBookingRequests(page?: number, size?: number, order?: GetBookingRequestsOrderEnum, sort?: string, options?: RawAxiosRequestConfig) {
        return BookingRequestApiFp(this.configuration).getBookingRequests(page, size, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update booking request by id
     * @param {string} id 
     * @param {BookingRequest} bookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingRequestApi
     */
    public updateBookingRequest(id: string, bookingRequest: BookingRequest, options?: RawAxiosRequestConfig) {
        return BookingRequestApiFp(this.configuration).updateBookingRequest(id, bookingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetBookingRequestsOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetBookingRequestsOrderEnum = typeof GetBookingRequestsOrderEnum[keyof typeof GetBookingRequestsOrderEnum];


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create company
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (company: Company, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createCompany', 'company', company)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompany', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a company partially
         * @param {string} id 
         * @param {File} [companyLogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompany: async (id: string, companyLogo?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCompany', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (companyLogo !== undefined) { 
                localVarFormParams.append('companyLogo', companyLogo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company by id
         * @param {string} id 
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (id: string, company: Company, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompany', 'id', id)
            // verify required parameter 'company' is not null or undefined
            assertParamExists('updateCompany', 'company', company)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create company
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(company: Company, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(company, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a company partially
         * @param {string} id 
         * @param {File} [companyLogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCompany(id: string, companyLogo?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCompany(id, companyLogo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.patchCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update company by id
         * @param {string} id 
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(id: string, company: Company, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(id, company, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create company
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(company: Company, options?: any): AxiosPromise<Company> {
            return localVarFp.createCompany(company, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(id: string, options?: any): AxiosPromise<Company> {
            return localVarFp.getCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a company partially
         * @param {string} id 
         * @param {File} [companyLogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompany(id: string, companyLogo?: File, options?: any): AxiosPromise<Company> {
            return localVarFp.patchCompany(id, companyLogo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company by id
         * @param {string} id 
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(id: string, company: Company, options?: any): AxiosPromise<Company> {
            return localVarFp.updateCompany(id, company, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Create company
     * @param {Company} company 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCompany(company: Company, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCompany(company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompany(id: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a company partially
     * @param {string} id 
     * @param {File} [companyLogo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public patchCompany(id: string, companyLogo?: File, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).patchCompany(id, companyLogo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company by id
     * @param {string} id 
     * @param {Company} company 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany(id: string, company: Company, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompany(id, company, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create customer
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (customer: Customer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('createCustomer', 'customer', customer)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomer', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCustomer', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all customers
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetCustomersOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (page?: number, size?: number, order?: GetCustomersOrderEnum, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer by id
         * @param {string} id 
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: async (id: string, customer: Customer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomer', 'id', id)
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('updateCustomer', 'customer', customer)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create customer
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(customer: Customer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(customer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.deleteCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all customers
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetCustomersOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(page?: number, size?: number, order?: GetCustomersOrderEnum, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(page, size, order, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getCustomers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update customer by id
         * @param {string} id 
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomer(id: string, customer: Customer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer(id, customer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create customer
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(customer: Customer, options?: any): AxiosPromise<Customer> {
            return localVarFp.createCustomer(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer(id: string, options?: any): AxiosPromise<Customer> {
            return localVarFp.getCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all customers
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetCustomersOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(page?: number, size?: number, order?: GetCustomersOrderEnum, sort?: string, options?: any): AxiosPromise<GetCustomers200Response> {
            return localVarFp.getCustomers(page, size, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer by id
         * @param {string} id 
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(id: string, customer: Customer, options?: any): AxiosPromise<Customer> {
            return localVarFp.updateCustomer(id, customer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Create customer
     * @param {Customer} customer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCustomer(customer: Customer, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomer(id: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomer(id: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all customers
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetCustomersOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomers(page?: number, size?: number, order?: GetCustomersOrderEnum, sort?: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomers(page, size, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer by id
     * @param {string} id 
     * @param {Customer} customer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomer(id: string, customer: Customer, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateCustomer(id, customer, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetCustomersOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetCustomersOrderEnum = typeof GetCustomersOrderEnum[keyof typeof GetCustomersOrderEnum];


/**
 * DogApi - axios parameter creator
 * @export
 */
export const DogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create dog
         * @param {Dog} dog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDog: async (dog: Dog, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dog' is not null or undefined
            assertParamExists('createDog', 'dog', dog)
            const localVarPath = `/dogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete dog by id
         * @param {string} dogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDog: async (dogId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dogId' is not null or undefined
            assertParamExists('deleteDog', 'dogId', dogId)
            const localVarPath = `/dogs/{dogId}`
                .replace(`{${"dogId"}}`, encodeURIComponent(String(dogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dog by id
         * @param {string} dogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDog: async (dogId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dogId' is not null or undefined
            assertParamExists('getDog', 'dogId', dogId)
            const localVarPath = `/dogs/{dogId}`
                .replace(`{${"dogId"}}`, encodeURIComponent(String(dogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all dogs
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetDogsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDogs: async (page?: number, size?: number, order?: GetDogsOrderEnum, sort?: string, customerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update dog by id
         * @param {string} dogId 
         * @param {Dog} dog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDog: async (dogId: string, dog: Dog, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dogId' is not null or undefined
            assertParamExists('updateDog', 'dogId', dogId)
            // verify required parameter 'dog' is not null or undefined
            assertParamExists('updateDog', 'dog', dog)
            const localVarPath = `/dogs/{dogId}`
                .replace(`{${"dogId"}}`, encodeURIComponent(String(dogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DogApi - functional programming interface
 * @export
 */
export const DogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create dog
         * @param {Dog} dog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDog(dog: Dog, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDog(dog, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DogApi.createDog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete dog by id
         * @param {string} dogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDog(dogId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDog(dogId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DogApi.deleteDog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get dog by id
         * @param {string} dogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDog(dogId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDog(dogId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DogApi.getDog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all dogs
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetDogsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDogs(page?: number, size?: number, order?: GetDogsOrderEnum, sort?: string, customerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDogs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDogs(page, size, order, sort, customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DogApi.getDogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update dog by id
         * @param {string} dogId 
         * @param {Dog} dog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDog(dogId: string, dog: Dog, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDog(dogId, dog, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DogApi.updateDog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DogApi - factory interface
 * @export
 */
export const DogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DogApiFp(configuration)
    return {
        /**
         * 
         * @summary Create dog
         * @param {Dog} dog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDog(dog: Dog, options?: any): AxiosPromise<Dog> {
            return localVarFp.createDog(dog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete dog by id
         * @param {string} dogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDog(dogId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDog(dogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dog by id
         * @param {string} dogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDog(dogId: string, options?: any): AxiosPromise<Dog> {
            return localVarFp.getDog(dogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all dogs
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetDogsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDogs(page?: number, size?: number, order?: GetDogsOrderEnum, sort?: string, customerId?: string, options?: any): AxiosPromise<GetDogs200Response> {
            return localVarFp.getDogs(page, size, order, sort, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update dog by id
         * @param {string} dogId 
         * @param {Dog} dog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDog(dogId: string, dog: Dog, options?: any): AxiosPromise<Dog> {
            return localVarFp.updateDog(dogId, dog, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DogApi - object-oriented interface
 * @export
 * @class DogApi
 * @extends {BaseAPI}
 */
export class DogApi extends BaseAPI {
    /**
     * 
     * @summary Create dog
     * @param {Dog} dog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DogApi
     */
    public createDog(dog: Dog, options?: RawAxiosRequestConfig) {
        return DogApiFp(this.configuration).createDog(dog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete dog by id
     * @param {string} dogId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DogApi
     */
    public deleteDog(dogId: string, options?: RawAxiosRequestConfig) {
        return DogApiFp(this.configuration).deleteDog(dogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dog by id
     * @param {string} dogId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DogApi
     */
    public getDog(dogId: string, options?: RawAxiosRequestConfig) {
        return DogApiFp(this.configuration).getDog(dogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all dogs
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetDogsOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {string} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DogApi
     */
    public getDogs(page?: number, size?: number, order?: GetDogsOrderEnum, sort?: string, customerId?: string, options?: RawAxiosRequestConfig) {
        return DogApiFp(this.configuration).getDogs(page, size, order, sort, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update dog by id
     * @param {string} dogId 
     * @param {Dog} dog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DogApi
     */
    public updateDog(dogId: string, dog: Dog, options?: RawAxiosRequestConfig) {
        return DogApiFp(this.configuration).updateDog(dogId, dog, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetDogsOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetDogsOrderEnum = typeof GetDogsOrderEnum[keyof typeof GetDogsOrderEnum];


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new Event
         * @param {CreateEvent} createEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent: async (createEvent: CreateEvent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEvent' is not null or undefined
            assertParamExists('createEvent', 'createEvent', createEvent)
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete event by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEvent', 'id', id)
            const localVarPath = `/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get event by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEvent', 'id', id)
            const localVarPath = `/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all events
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetEventsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (page?: number, size?: number, order?: GetEventsOrderEnum, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update event by id
         * @param {string} id 
         * @param {Event} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: async (id: string, event: Event, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEvent', 'id', id)
            // verify required parameter 'event' is not null or undefined
            assertParamExists('updateEvent', 'event', event)
            const localVarPath = `/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(event, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new Event
         * @param {CreateEvent} createEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvent(createEvent: CreateEvent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(createEvent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventApi.createEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete event by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvent(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventApi.deleteEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get event by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventApi.getEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all events
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetEventsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(page?: number, size?: number, order?: GetEventsOrderEnum, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(page, size, order, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventApi.getEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update event by id
         * @param {string} id 
         * @param {Event} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvent(id: string, event: Event, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(id, event, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventApi.updateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new Event
         * @param {CreateEvent} createEvent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(createEvent: CreateEvent, options?: any): AxiosPromise<Event> {
            return localVarFp.createEvent(createEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete event by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get event by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: string, options?: any): AxiosPromise<Event> {
            return localVarFp.getEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all events
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetEventsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(page?: number, size?: number, order?: GetEventsOrderEnum, sort?: string, options?: any): AxiosPromise<EventsPaged> {
            return localVarFp.getEvents(page, size, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update event by id
         * @param {string} id 
         * @param {Event} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(id: string, event: Event, options?: any): AxiosPromise<Event> {
            return localVarFp.updateEvent(id, event, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new Event
     * @param {CreateEvent} createEvent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public createEvent(createEvent: CreateEvent, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).createEvent(createEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete event by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public deleteEvent(id: string, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).deleteEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get event by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvent(id: string, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).getEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all events
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetEventsOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvents(page?: number, size?: number, order?: GetEventsOrderEnum, sort?: string, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).getEvents(page, size, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update event by id
     * @param {string} id 
     * @param {Event} event 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public updateEvent(id: string, event: Event, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).updateEvent(id, event, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetEventsOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetEventsOrderEnum = typeof GetEventsOrderEnum[keyof typeof GetEventsOrderEnum];


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create message for appointment
         * @param {string} appointmentId 
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageForAppointment: async (appointmentId: string, message: Message, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('createMessageForAppointment', 'appointmentId', appointmentId)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('createMessageForAppointment', 'message', message)
            const localVarPath = `/messages/appointment/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(message, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create message
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessages: async (message: Message, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message' is not null or undefined
            assertParamExists('createMessages', 'message', message)
            const localVarPath = `/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(message, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMessage', 'id', id)
            const localVarPath = `/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all messages
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetMessagesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [recipientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (page?: number, size?: number, order?: GetMessagesOrderEnum, sort?: string, recipientId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (recipientId !== undefined) {
                localVarQueryParameter['recipientId'] = recipientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create message for appointment
         * @param {string} appointmentId 
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessageForAppointment(appointmentId: string, message: Message, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessageForAppointment(appointmentId, message, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.createMessageForAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create message
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessages(message: Message, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessages(message, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.createMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.getMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all messages
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetMessagesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [recipientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(page?: number, size?: number, order?: GetMessagesOrderEnum, sort?: string, recipientId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMessages200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(page, size, order, sort, recipientId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageApi.getMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Create message for appointment
         * @param {string} appointmentId 
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessageForAppointment(appointmentId: string, message: Message, options?: any): AxiosPromise<Array<AppointmentMessage>> {
            return localVarFp.createMessageForAppointment(appointmentId, message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create message
         * @param {Message} message 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessages(message: Message, options?: any): AxiosPromise<Array<Message>> {
            return localVarFp.createMessages(message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get message by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage(id: string, options?: any): AxiosPromise<Message> {
            return localVarFp.getMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all messages
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetMessagesOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {string} [recipientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(page?: number, size?: number, order?: GetMessagesOrderEnum, sort?: string, recipientId?: string, options?: any): AxiosPromise<GetMessages200Response> {
            return localVarFp.getMessages(page, size, order, sort, recipientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary Create message for appointment
     * @param {string} appointmentId 
     * @param {Message} message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public createMessageForAppointment(appointmentId: string, message: Message, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).createMessageForAppointment(appointmentId, message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create message
     * @param {Message} message 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public createMessages(message: Message, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).createMessages(message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get message by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessage(id: string, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).getMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all messages
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetMessagesOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {string} [recipientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessages(page?: number, size?: number, order?: GetMessagesOrderEnum, sort?: string, recipientId?: string, options?: RawAxiosRequestConfig) {
        return MessageApiFp(this.configuration).getMessages(page, size, order, sort, recipientId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetMessagesOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetMessagesOrderEnum = typeof GetMessagesOrderEnum[keyof typeof GetMessagesOrderEnum];


/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a booking request
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublicBookingRequest: async (bookingRequest: BookingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRequest' is not null or undefined
            assertParamExists('createPublicBookingRequest', 'bookingRequest', bookingRequest)
            const localVarPath = `/public/booking-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Give it to me
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCalendarPublic: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getBookingCalendarPublic', 'companyId', companyId)
            const localVarPath = `/public/booking-calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Give it to me
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {string} trainerId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCalendarTimeSlotsPublic: async (companyId: string, articleId: string, trainerId: string, start: string, end: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getBookingCalendarTimeSlotsPublic', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getBookingCalendarTimeSlotsPublic', 'articleId', articleId)
            // verify required parameter 'trainerId' is not null or undefined
            assertParamExists('getBookingCalendarTimeSlotsPublic', 'trainerId', trainerId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getBookingCalendarTimeSlotsPublic', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getBookingCalendarTimeSlotsPublic', 'end', end)
            const localVarPath = `/public/booking-calendar/time-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (articleId !== undefined) {
                localVarQueryParameter['articleId'] = articleId;
            }

            if (trainerId !== undefined) {
                localVarQueryParameter['trainerId'] = trainerId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all public appointments
         * @param {string} companyId 
         * @param {string} eventId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetPublicAppointmentsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicAppointments: async (companyId: string, eventId: string, page?: number, size?: number, order?: GetPublicAppointmentsOrderEnum, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPublicAppointments', 'companyId', companyId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getPublicAppointments', 'eventId', eventId)
            const localVarPath = `/public/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public article by id
         * @param {string} id 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticle: async (id: string, companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPublicArticle', 'id', id)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPublicArticle', 'companyId', companyId)
            const localVarPath = `/public/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public event by id
         * @param {string} id 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEvent: async (id: string, companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPublicEvent', 'id', id)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPublicEvent', 'companyId', companyId)
            const localVarPath = `/public/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all public events
         * @param {string} companyId 
         * @param {EventType} [eventType] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetPublicEventsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEvents: async (companyId: string, eventType?: EventType, page?: number, size?: number, order?: GetPublicEventsOrderEnum, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPublicEvents', 'companyId', companyId)
            const localVarPath = `/public/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public settings of company
         * @param {string} companyId 
         * @param {Array<string>} [properties] Specific settings to retrieve, comma-separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSettings: async (companyId: string, properties?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPublicSettings', 'companyId', companyId)
            const localVarPath = `/public/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (properties) {
                localVarQueryParameter['properties'] = properties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a booking request
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPublicBookingRequest(bookingRequest: BookingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPublicBookingRequest(bookingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.createPublicBookingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Give it to me
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingCalendarPublic(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBookingCalendarPublic200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingCalendarPublic(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.getBookingCalendarPublic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Give it to me
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {string} trainerId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingCalendarTimeSlotsPublic(companyId: string, articleId: string, trainerId: string, start: string, end: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBookingCalendarTimeSlotsPublic200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingCalendarTimeSlotsPublic(companyId, articleId, trainerId, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.getBookingCalendarTimeSlotsPublic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all public appointments
         * @param {string} companyId 
         * @param {string} eventId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetPublicAppointmentsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicAppointments(companyId: string, eventId: string, page?: number, size?: number, order?: GetPublicAppointmentsOrderEnum, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppointmentsPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicAppointments(companyId, eventId, page, size, order, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.getPublicAppointments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get public article by id
         * @param {string} id 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicArticle(id: string, companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicArticle(id, companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.getPublicArticle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get public event by id
         * @param {string} id 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicEvent(id: string, companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicEvent(id, companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.getPublicEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all public events
         * @param {string} companyId 
         * @param {EventType} [eventType] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetPublicEventsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicEvents(companyId: string, eventType?: EventType, page?: number, size?: number, order?: GetPublicEventsOrderEnum, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicEvents(companyId, eventType, page, size, order, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.getPublicEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get public settings of company
         * @param {string} companyId 
         * @param {Array<string>} [properties] Specific settings to retrieve, comma-separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicSettings(companyId: string, properties?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicSettings(companyId, properties, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicApi.getPublicSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a booking request
         * @param {BookingRequest} bookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublicBookingRequest(bookingRequest: BookingRequest, options?: any): AxiosPromise<BookingRequest> {
            return localVarFp.createPublicBookingRequest(bookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Give it to me
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCalendarPublic(companyId: string, options?: any): AxiosPromise<GetBookingCalendarPublic200Response> {
            return localVarFp.getBookingCalendarPublic(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Give it to me
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {string} trainerId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingCalendarTimeSlotsPublic(companyId: string, articleId: string, trainerId: string, start: string, end: string, options?: any): AxiosPromise<GetBookingCalendarTimeSlotsPublic200Response> {
            return localVarFp.getBookingCalendarTimeSlotsPublic(companyId, articleId, trainerId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all public appointments
         * @param {string} companyId 
         * @param {string} eventId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetPublicAppointmentsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicAppointments(companyId: string, eventId: string, page?: number, size?: number, order?: GetPublicAppointmentsOrderEnum, sort?: string, options?: any): AxiosPromise<AppointmentsPaged> {
            return localVarFp.getPublicAppointments(companyId, eventId, page, size, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public article by id
         * @param {string} id 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicArticle(id: string, companyId: string, options?: any): AxiosPromise<Article> {
            return localVarFp.getPublicArticle(id, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public event by id
         * @param {string} id 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEvent(id: string, companyId: string, options?: any): AxiosPromise<Event> {
            return localVarFp.getPublicEvent(id, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all public events
         * @param {string} companyId 
         * @param {EventType} [eventType] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {GetPublicEventsOrderEnum} [order] Order
         * @param {string} [sort] Sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicEvents(companyId: string, eventType?: EventType, page?: number, size?: number, order?: GetPublicEventsOrderEnum, sort?: string, options?: any): AxiosPromise<EventsPaged> {
            return localVarFp.getPublicEvents(companyId, eventType, page, size, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public settings of company
         * @param {string} companyId 
         * @param {Array<string>} [properties] Specific settings to retrieve, comma-separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSettings(companyId: string, properties?: Array<string>, options?: any): AxiosPromise<PublicSettings> {
            return localVarFp.getPublicSettings(companyId, properties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @summary Create a booking request
     * @param {BookingRequest} bookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public createPublicBookingRequest(bookingRequest: BookingRequest, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).createPublicBookingRequest(bookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Give it to me
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getBookingCalendarPublic(companyId: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).getBookingCalendarPublic(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Give it to me
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {string} trainerId 
     * @param {string} start 
     * @param {string} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getBookingCalendarTimeSlotsPublic(companyId: string, articleId: string, trainerId: string, start: string, end: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).getBookingCalendarTimeSlotsPublic(companyId, articleId, trainerId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all public appointments
     * @param {string} companyId 
     * @param {string} eventId 
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetPublicAppointmentsOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPublicAppointments(companyId: string, eventId: string, page?: number, size?: number, order?: GetPublicAppointmentsOrderEnum, sort?: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPublicAppointments(companyId, eventId, page, size, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public article by id
     * @param {string} id 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPublicArticle(id: string, companyId: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPublicArticle(id, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public event by id
     * @param {string} id 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPublicEvent(id: string, companyId: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPublicEvent(id, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all public events
     * @param {string} companyId 
     * @param {EventType} [eventType] 
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {GetPublicEventsOrderEnum} [order] Order
     * @param {string} [sort] Sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPublicEvents(companyId: string, eventType?: EventType, page?: number, size?: number, order?: GetPublicEventsOrderEnum, sort?: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPublicEvents(companyId, eventType, page, size, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public settings of company
     * @param {string} companyId 
     * @param {Array<string>} [properties] Specific settings to retrieve, comma-separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPublicSettings(companyId: string, properties?: Array<string>, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPublicSettings(companyId, properties, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetPublicAppointmentsOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetPublicAppointmentsOrderEnum = typeof GetPublicAppointmentsOrderEnum[keyof typeof GetPublicAppointmentsOrderEnum];
/**
 * @export
 */
export const GetPublicEventsOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetPublicEventsOrderEnum = typeof GetPublicEventsOrderEnum[keyof typeof GetPublicEventsOrderEnum];


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get company settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company settings
         * @param {Settings} settings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySettings: async (settings: Settings, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settings' is not null or undefined
            assertParamExists('updateCompanySettings', 'settings', settings)
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get company settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.getCompanySettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update company settings
         * @param {Settings} settings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanySettings(settings: Settings, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanySettings(settings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.updateCompanySettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get company settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySettings(options?: any): AxiosPromise<Settings> {
            return localVarFp.getCompanySettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company settings
         * @param {Settings} settings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySettings(settings: Settings, options?: any): AxiosPromise<Settings> {
            return localVarFp.updateCompanySettings(settings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get company settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getCompanySettings(options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getCompanySettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company settings
     * @param {Settings} settings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateCompanySettings(settings: Settings, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateCompanySettings(settings, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new checkout session
         * @param {CreateCheckoutSessionRequest} createCheckoutSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession: async (createCheckoutSessionRequest: CreateCheckoutSessionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCheckoutSessionRequest' is not null or undefined
            assertParamExists('createCheckoutSession', 'createCheckoutSessionRequest', createCheckoutSessionRequest)
            const localVarPath = `/public/stripe/create-checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCheckoutSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionStatus: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/stripe/session-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new checkout session
         * @param {CreateCheckoutSessionRequest} createCheckoutSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCheckoutSession(createCheckoutSessionRequest: CreateCheckoutSessionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCheckoutSession200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCheckoutSession(createCheckoutSessionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.createCheckoutSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get session status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionStatus(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSessionStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionStatus(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StripeApi.getSessionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new checkout session
         * @param {CreateCheckoutSessionRequest} createCheckoutSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession(createCheckoutSessionRequest: CreateCheckoutSessionRequest, options?: any): AxiosPromise<CreateCheckoutSession200Response> {
            return localVarFp.createCheckoutSession(createCheckoutSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get session status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionStatus(options?: any): AxiosPromise<GetSessionStatus200Response> {
            return localVarFp.getSessionStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @summary Create a new checkout session
     * @param {CreateCheckoutSessionRequest} createCheckoutSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public createCheckoutSession(createCheckoutSessionRequest: CreateCheckoutSessionRequest, options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).createCheckoutSession(createCheckoutSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get session status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public getSessionStatus(options?: RawAxiosRequestConfig) {
        return StripeApiFp(this.configuration).getSessionStatus(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users
         * @param {boolean} [isTrainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (isTrainer?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isTrainer !== undefined) {
                localVarQueryParameter['is_trainer'] = isTrainer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates currently logged in user
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: async (avatar?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (avatar !== undefined) { 
                localVarFormParams.append('avatar', avatar as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a new user
         * @param {CreateUserWithCompany} createUserWithCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (createUserWithCompany: CreateUserWithCompany, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserWithCompany' is not null or undefined
            assertParamExists('registerUser', 'createUserWithCompany', createUserWithCompany)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserWithCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get users
         * @param {boolean} [isTrainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(isTrainer?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(isTrainer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates currently logged in user
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUser(avatar?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUser(avatar, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.patchUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Register a new user
         * @param {CreateUserWithCompany} createUserWithCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(createUserWithCompany: CreateUserWithCompany, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(createUserWithCompany, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.registerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users
         * @param {boolean} [isTrainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(isTrainer?: boolean, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(isTrainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates currently logged in user
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(avatar?: File, options?: any): AxiosPromise<User> {
            return localVarFp.patchUser(avatar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a new user
         * @param {CreateUserWithCompany} createUserWithCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(createUserWithCompany: CreateUserWithCompany, options?: any): AxiosPromise<void> {
            return localVarFp.registerUser(createUserWithCompany, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users
     * @param {boolean} [isTrainer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(isTrainer?: boolean, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(isTrainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates currently logged in user
     * @param {File} [avatar] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public patchUser(avatar?: File, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).patchUser(avatar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a new user
     * @param {CreateUserWithCompany} createUserWithCompany 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerUser(createUserWithCompany: CreateUserWithCompany, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).registerUser(createUserWithCompany, options).then((request) => request(this.axios, this.basePath));
    }
}



