<script setup lang="ts">
import {useTheme} from 'vuetify'
import {useThemeConfig} from '@core/composable/useThemeConfig'
import {hexToRgb} from '@layouts/utils';
import ErrorView from "@/components/ErrorView.vue";

const {global} = useTheme();


const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  handleSkinChanges
} = useThemeConfig()


syncInitialLoaderTheme();
syncConfigThemeWithVuetifyTheme();
handleSkinChanges();


</script>

<template>
  <VLocaleProvider>
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView/>
      <ErrorView/>
    </VApp>
  </VLocaleProvider>
</template>
