import {User} from "@/api/generated";
import {usersApiClient} from "@/api/clients.ts";

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            user: {} as User
        }
    },
    getters: {
      userId: (state): string => state.user.id || ''
    },
    actions: {
        async patchUser(avatar?: File): Promise<User> {
            const response = (await usersApiClient.patchUser(avatar)).data;
            if (response) {
                this.user = response;
            }
            return this.getLoggedInUser();
        },

        async getLoggedInUser(): Promise<User> {
            if (this.user.id) {
                return this.user;
            }
            this.user = (await usersApiClient.getUser()).data
            return this.user;
        },

        async getUserById(userId: string): Promise<User> {
            return (await usersApiClient.getUserById(userId)).data;
        },
    }
})
