import {LogtoConfig} from "@logto/vue";

export const config: LogtoConfig = {
    endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
    appId: import.meta.env.VITE_LOGTO_APP_ID,
    resources: [import.meta.env.VITE_LOGTO_RESOURCE],
};

export const singInCallbackPath = `${window.location.protocol}//${window.location.host}/company/login-callback`;
export const singOutCallbackPath = `${window.location.protocol}//${window.location.host}/logout`;
