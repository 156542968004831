<script setup lang="ts">
import {useErrorStore} from "@/stores/errorStore.ts";

const errorStore = useErrorStore();
const showError = computed(() => errorStore.errors.length > 0);

const clearErrors = () => {
  errorStore.clearErrors();
}
</script>

<template>
  <div>
    <v-snackbar :model-value="showError">
      Wuff: {{ errorStore.errors[0].errorCode }}
      <template #actions>
        <v-btn @click="clearErrors" color="primary">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
